import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const Blog = ({ location, pageContext: { posts } }) => {
  // console.log("posts", posts)
  // console.log("posts", posts)
  const [sticky, ...rows] = posts
  // console.log("sticky", sticky)
  // let rows = subsequentPosts.reduce(function (rows, key, index) {
  //   return (
  //     (index % 2 === 0
  //       ? rows.push([key])
  //       : rows[rows.length - 1].push(key)) && rows
  //   )
  // }, [])
  return (
    <Layout location={location}>
      <Seo bodyClasses={`blog-archive`} seo="" />

      {sticky && (
        <section className="gray intro one">
          <div className="container">
            <div className="row">
              <div className="post col-md-5">
                <blockquote>
                  <ul className="list-group list-group-horizontal no-wrap">
                    {sticky.post.categories.nodes.map((category, i) => (
                      <li className="list-group-item border-0 p-0 me-1 text-uppercase" key={i}>
                        <Link to={category.uri} className="link-primary">
                          {category.name}
                        </Link>
                        {i < sticky.post.categories.nodes.length - 1 && ","}
                      </li>
                    ))}
                  </ul>
                </blockquote>
                <h3>{parse(sticky.post.title)}</h3>
                <div className='pr-5'>{parse(sticky.post.excerpt)}</div>
                <p className="mt-5">
                  <Link to={sticky.post.uri} className="link arrow">
                    Read more
                  </Link>
                </p>
              </div>
              <div className="offset-md-1 col-md-6 image">
                {!!sticky?.post?.featuredImage?.node?.localFile && (
                  <div>
                    <GatsbyImage
                      image={
                        sticky?.post.featuredImage.node.localFile.childImageSharp
                          .gatsbyImageData
                      }
                      alt={sticky?.post.featuredImage.node.altText}
                      loading="eager"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      {!!rows && (
        <section>
          <div className="container">
            {rows.map((blog, index) => {
              // console.log("post", blog)
              return (
                <div className="row post" key={index}>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-5 image">
                        {!!blog?.post?.featuredImage?.node?.localFile && (
                            <GatsbyImage
                              image={
                                blog.post.featuredImage.node.localFile
                                  .childImageSharp.gatsbyImageData
                              }
                              alt={blog.post.featuredImage.node.altText}
                            />
                        )}
                      </div>
                      <div className="offset-md-1 col-md-6">
                        <h4>{parse(blog.post.title)}</h4>
                        <blockquote className="d-none">
                          <ul className="list-group list-group-horizontal mb-0 no-wrap">
                            {blog.post.categories.nodes.map((category, i) => (
                              <li
                                className="list-group-item border-0 p-0 me-3"
                                key={i}
                              >
                                <Link
                                  to={category.uri}
                                  className="link-primary"
                                >
                                  {category.name}
                                </Link>
                                {i < blog.post.categories.nodes.length - 1 && ","}
                              </li>
                            ))}
                          </ul>
                        </blockquote>
                        {parse(blog.post.excerpt)}
                        <p className="mt-3">
                          <Link to={blog.post.uri} className="link arrow">
                            Read more
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      )}
    </Layout>
  )
}

export default Blog
